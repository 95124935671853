import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { Box, Divider, Stack, Typography } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import ToggleSwitch from "../../common/OnOffSwitch";
import { riskLevelCount } from "../../helpers/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "#0395FF",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "18.2px",
    padding: "20px 13px",
    fontFamily: "IBM Plex Sans",
    border: "none",
    borderBottom: "1px solid #344B5B0F",
  },
  [`&.${tableCellClasses.body}`]: {
    padding: "20px 13px",
    fontSize: 16,
    lineHeight: "20.8px",
    border: "none",
    borderBottom: "1px solid #344B5B0F",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  borderBottom: "none",
}));

export default function ListTable({
  ownerName,
  investorEmail,
  rows,
  selectedProperties,
  setSelectedProperties,
  selectedPremiumProperties,
  setSelectedPremiumProperties,
  isActionButton = true,
  loader = false,
  page = 0,
  userData = {},
}) {
  const isSelected = (properties = selectedProperties, id) => {
    // Check if the row is selected based on its id
    return properties?.some((selectedRow) => selectedRow?.id === id);
  };

  const selectProperties = (toggleVal, row) => {
    // Add the row to the selected rows (full row data)
    if (!isSelected(selectedProperties, row.id)) {
      setSelectedProperties((prevSelectedProperties) => [
        ...prevSelectedProperties,
        { ...row, enable: toggleVal },
      ]);
    } else {
      // If the row is already in the state, update its 'enable' value immutably
      setSelectedProperties((prevSelectedProperties) =>
        prevSelectedProperties.map(
          (property) =>
            property.id === row.id
              ? { ...property, enable: toggleVal } // Update the 'enable' value
              : property // Keep other properties unchanged
        )
      );
    }
  };
  const selectPremiumProperties = (toggleVal, row) => {
    // Add the row to the selected rows (full row data)
    if (!isSelected(selectedPremiumProperties, row.id)) {
      setSelectedPremiumProperties((prevSelectedProperties) => [
        ...prevSelectedProperties,
        { ...row, enable: toggleVal },
      ]);
    } else {
      // If the row is already in the state, update its 'enable' value immutably
      setSelectedPremiumProperties((prevSelectedProperties) =>
        prevSelectedProperties.map(
          (property) =>
            property.id === row.id
              ? { ...property, enable: toggleVal } // Update the 'enable' value
              : property // Keep other properties unchanged
        )
      );
    }
  };

  let freeMonitoringToggleValue = {};
  let getProtectedToggleValue = {};
  return (
    <>
      {/* Desktop Table */}
      <TableContainer
        component={Paper}
        className="d-none d-sm-block"
        sx={{ maxHeight: 885, boxShadow: "0px 1px 0px 0px #344B5B0F" }}
      >
        <Table sx={{ minWidth: 1200 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Address</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Zip</StyledTableCell>
              {/* <StyledTableCell>Unit</StyledTableCell> */}
              {/* <StyledTableCell>Sold In</StyledTableCell> */}
              <StyledTableCell width={200}>Owner</StyledTableCell>
              <StyledTableCell>APN</StyledTableCell>
              <StyledTableCell width={200}>Risk Level</StyledTableCell>
              <StyledTableCell width={"max-content"}>
                Free Monitoring
              </StyledTableCell>
              <StyledTableCell width={"max-content"}>
                Get Protected
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row, index) => {
              // For remain Toggle values same as before changing page
              freeMonitoringToggleValue = selectedProperties?.find(
                (data) => data?.id === row?.id
              );
              getProtectedToggleValue = selectedPremiumProperties?.find(
                (data) => data?.id === row?.id
              );
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{page + index + 1}</StyledTableCell>
                  <StyledTableCell>{row?.site_address}</StyledTableCell>
                  <StyledTableCell>{row?.site_city}</StyledTableCell>
                  <StyledTableCell>{row?.site_state}</StyledTableCell>
                  <StyledTableCell>{row?.site_zipcode}</StyledTableCell>
                  {/* <StyledTableCell>{row?.unit ?? "-"}</StyledTableCell> */}
                  {/* <StyledTableCell>{row?.soldIn ?? "-"}</StyledTableCell> */}
                  <StyledTableCell width={200}>{ownerName}</StyledTableCell>
                  <StyledTableCell>{row.apn_number}</StyledTableCell>
                  <StyledTableCell width={200}>
                    <Stack direction="column" spacing={0.5} className="">
                      <Typography className="value">
                        <div className="d-flex justify-content-start align-items-center">
                          {
                            <>
                              <div
                                class={` color-box ${
                                  riskLevelCount(row?.risk_level) > 0 &&
                                  " color-low"
                                } `}
                              ></div>
                              <div
                                class={` color-box ${
                                  riskLevelCount(row?.risk_level) > 1 &&
                                  " color-medium-low"
                                } `}
                              ></div>
                              <div
                                class={` color-box ${
                                  riskLevelCount(row?.risk_level) > 2 &&
                                  " color-medium"
                                } `}
                              ></div>
                              <div
                                class={` color-box ${
                                  riskLevelCount(row?.risk_level) > 3 &&
                                  " color-medium-high"
                                } `}
                              ></div>
                              <div
                                class={` color-box ${
                                  riskLevelCount(row?.risk_level) > 4 &&
                                  " color-high"
                                } `}
                              ></div>
                            </>
                          }
                          <span className="ps-1 fw-500">{row?.risk_level}</span>
                        </div>
                      </Typography>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell width={"max-content"}>
                    <ToggleSwitch
                      loader={loader}
                      initialState={
                        freeMonitoringToggleValue
                          ? freeMonitoringToggleValue?.enable
                          : row?.free_subscription
                      }
                      onChange={(toggleVal) => {
                        selectProperties(toggleVal, row);
                      }}
                    />
                  </StyledTableCell>
                  {/* <Switch checked={row.getProtected} /> */}
                  <StyledTableCell width={"max-content"}>
                    <ToggleSwitch
                      loader={loader}
                      index={index}
                      initialState={
                        getProtectedToggleValue
                          ? getProtectedToggleValue?.enable
                          : row?.hold_for_signup
                      }
                      onChange={(toggleVal) => {
                        selectPremiumProperties(toggleVal, row);
                      }}
                      disable={
                        userData?.json_last_step?.step >= 4 &&
                        row?.hold_for_signup
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>

        {!rows?.length > 0 && (
          <Typography className="p-5 text-center" variant="h6">
            {"No Data Found."}
          </Typography>
        )}
      </TableContainer>
      {/* Mobile View */}
      <Box className="d-block d-sm-none mobile-table bg-white px-0 mb-3 pt-4 ">
        {rows?.map((row, index) => {
          // For remain Toggle values same as before changing page
          freeMonitoringToggleValue = selectedProperties?.find(
            (data) => data?.id === row?.id
          );
          getProtectedToggleValue = selectedPremiumProperties?.find(
            (data) => data?.id === row?.id
          );
          return (
            <>
              <Stack
                direction="row"
                alignItems="baseline"
                justifyContent={"space-between"}
                spacing={0}
                p={1.4}
                pt={2}
                mb={2}
              >
                <Stack direction="row" alignItems="baseline">
                  <div>
                    <Stack direction="column" spacing={1} px={1}>
                      <Typography
                        className="key fw-500"
                        sx={{ fontSize: "15px !important" }}
                      >
                        Address
                      </Typography>
                      <Stack direction="column" spacing={0.5}>
                        <Typography className="value mt-1">
                          {row?.site_address || "-"}
                        </Typography>
                        <Typography className="value mt-2">
                          {row?.site_city || "-"} {row?.site_state || "-"}{" "}
                          {row?.site_zipcode || "-"}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={4} className="my-3">
                        <>
                          <Stack direction="column" spacing={0.5}>
                            <Typography className="key">Owner</Typography>
                            <Typography className="value">
                              {ownerName}
                            </Typography>
                          </Stack>
                        </>
                        <>
                          <Stack direction="column" spacing={0.5}>
                            <Typography className="key">APN</Typography>
                            <Typography className="value">
                              {row?.apn_number}
                            </Typography>
                          </Stack>
                        </>
                      </Stack>
                      <Stack direction="row" spacing={4} className="my-3">
                        <>
                          {/* <Stack direction="column" spacing={1}>
                            <Typography className="key">Units</Typography>
                            <Typography className="value">
                              {row?.unit ?? "-"}
                            </Typography>
                          </Stack> */}
                        </>
                        <>
                          {/* <Stack direction="column" spacing={0.5}>
                            <Typography className="key">Sold In</Typography>
                            <Typography className="value">
                              {row?.soldIn ?? "-"}
                            </Typography>
                          </Stack> */}
                        </>
                        <Stack direction="column" spacing={1} className="mb-3">
                          <Typography className="key">Risk Score</Typography>
                          <Typography className="value">
                            <div className="d-flex justify-content-start align-items-center">
                              {/* {isActionButton && ( */}
                              <>
                                <div
                                  class={` color-box ${
                                    riskLevelCount(row?.risk_level) > 0 &&
                                    " color-low"
                                  } `}
                                ></div>
                                <div
                                  class={` color-box ${
                                    riskLevelCount(row?.risk_level) > 1 &&
                                    " color-medium-low"
                                  } `}
                                ></div>
                                <div
                                  class={` color-box ${
                                    riskLevelCount(row?.risk_level) > 2 &&
                                    " color-medium"
                                  } `}
                                ></div>
                                <div
                                  class={` color-box ${
                                    riskLevelCount(row?.risk_level) > 3 &&
                                    " color-medium-high"
                                  } `}
                                ></div>
                                <div
                                  class={` color-box ${
                                    riskLevelCount(row?.risk_level) > 4 &&
                                    " color-high"
                                  } `}
                                ></div>
                              </>
                              {/* )} */}
                              <span className="ps-1 fw-500">
                                {row?.risk_level}
                              </span>
                            </div>
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={4} className="my-3">
                        <Stack direction="column" spacing={0.5}>
                          <Typography className="key">
                            Free Monitoring
                          </Typography>
                          <Typography className="value">
                            <ToggleSwitch
                              loader={loader}
                              initialState={
                                freeMonitoringToggleValue
                                  ? freeMonitoringToggleValue?.enable
                                  : row?.free_subscription
                              }
                              onChange={(toggleVal) => {
                                selectProperties(toggleVal, row);
                              }}
                            />
                            {/* {row?.free_subscription ? "Enable" : "Disable"} */}
                          </Typography>
                        </Stack>
                        <Stack direction="column" spacing={0.5}>
                          <Typography className="key">Get Protected</Typography>
                          <Typography className="value">
                            <ToggleSwitch
                              loader={loader}
                              index={index}
                              initialState={
                                getProtectedToggleValue
                                  ? getProtectedToggleValue?.enable
                                  : row?.hold_for_signup
                              }
                              onChange={(toggleVal) => {
                                selectPremiumProperties(toggleVal, row);
                              }}
                              disable={
                                userData?.json_last_step?.step >= 4 &&
                                row?.hold_for_signup
                              }
                            />
                            {/* {row?.free_subscription ? "Enable" : "Disable"} */}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>
                <>
                  {/* <div>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        // handleMenuClick(e, row);
                      }}
                      size="small"
                      sx={{ color: "rgba(249, 115, 22, 1)" }}
                    >
                      <SettingsIconSVG color={"rgba(249, 115, 22, 1)"} />
                    </IconButton>
                  </div> */}
                </>
              </Stack>

              {index < rows?.length - 1 && (
                <Divider
                  className="my-2 mt-3"
                  sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                />
              )}
            </>
          );
        })}

        {rows && !rows?.length > 0 && (
          <Typography
            className="d-block d-sm-none p-5 text-center"
            variant="h6"
          >
            No Data Found.
          </Typography>
        )}
      </Box>
    </>
  );
}
