import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import ListTable from "./ListTable";
import useTimer from "../../hooks/useTimer";
import { Loader } from "../../common/loader";
import CallUsNow from "../../common/CallUsNow";
import DialogBox from "../../common/DialogBox";
import AlertDialog from "../../common/AlertDialog";
import logo_blk from "../../../assets/logo/ep_new.svg";
import { Callto } from "../../common/ContcatAdminSupport";
import phoneTalk from "../../../assets/phone_in_talk.svg";
import {
  clearLocalStoarge,
  currencyFormatter,
  getObjLength,
} from "../../helpers/utils";
import FreeMonitoringUserForm from "../../county-recorder/FreeMonitoringUserForm";
import HolidayVillage from "../../../assets/properties/holiday_village.webp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import {
  PER_PAGE,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMAT,
} from "../../helpers/constants";
import { propertyRiskManagementAction } from "../../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

import "./investor-list.scss";
import RiskoMeterChart from "../../common/charts/RiskoMeterChart";
import { Container } from "react-bootstrap";
import EpOrbitTransitionSection from "../../common/EpOrbitTransitionSection";
const InvestorList = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //initializes the timer states for hook
  const { timer, startTimer, stopTimer, formatTimeForResendOTP } = useTimer(10);

  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedPremiumProperties, setSelectedPremiumProperties] = useState(
    []
  );
  const [isSelectePropertyModal, setIsSelectePropertyModal] = useState(false);
  const [inValidUUID, setInValidUUID] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [perPageOptions, setPerPageOptions] = useState([10, 20]); // Initial options
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [payload, setPayload] = useState({ offset: 0, limit: PER_PAGE });

  const {
    investorReportResultData,
    investorReportResultsLoader,
    investorMonitoringData,
    optionType,
    investorProeprtyMonitoringData,
    selectedPropertiesData,
  } = useSelector((state) => state?.propertyRiskManagement);

  const investoraData = investorReportResultData?.success
    ? investorReportResultData?.data
    : {};

  const handlePageChange = (e, p) => {
    setPage(p);
    setOffset((p - 1) * PER_PAGE);
    setPayload((prevState) => ({
      ...prevState,
      offset:
        limit > 10 ? (limit / 10) * (p - 1) * PER_PAGE : (p - 1) * PER_PAGE,
    }));
  };

  const ListAPIcall = useCallback(() => {
    let data = {
      token: uuid,
      limit: payload?.limit,
      offset: payload?.offset,
    };
    dispatch(
      propertyRiskManagementAction.getInvestorReportResultData({
        url: `get-investor`,
        data: data,
      })
    );
  }, [payload?.limit, payload?.offset, uuid, dispatch]);

  useEffect(() => {
    ListAPIcall();
  }, [ListAPIcall]);

  const closeModal = () => {
    setIsSelectePropertyModal(false);
  };

  const handleSaveChange = () => {
    setIsSelectePropertyModal(false);
    // const notSelectedRows = investorReportResultData?.data?.properties?.filter(
    //   (row) =>
    //     !selectedProperties.some((selectedRow) => selectedRow.id === row.id)
    // );
    // console.log("notSelectedRows-->", notSelectedRows);

    let freeMonitoringProperties = [];
    let premiumSubscriptionProperties = [];

    // for Free Monitoring
    if (selectedProperties?.length > 0) {
      const { freeProeprtyIDs } = selectedProperties?.reduce(
        (acc, item) => {
          if (
            (!item?.free_subscription && !item?.enable) ||
            (item?.free_subscription && item?.enable)
          ) {
            console.log("removed from the list");
            //  acc.freeProeprtyIDs.push({ ...item, enable: false }); // Add to inActiveFreeMonitoringProeprtyIDs if free_subscription is false
          } else {
            acc.freeProeprtyIDs.push({
              id: item?.id,
              property_id: item?.property_id,
              enable: item?.enable,
            }); // Add to activeFreeMonitoringProeprtyIDs otherwise
          }
          return acc;
        },
        { freeProeprtyIDs: [] } // Initialize with empty arrays
      );
      freeMonitoringProperties = freeProeprtyIDs;
    }
    // for Premium Subscription
    if (selectedPremiumProperties?.length > 0) {
      const { premiumProeprtyIDs, selectedPropertiesforSignup } =
        selectedPremiumProperties?.reduce(
          (acc, item) => {
            if (
              (!item?.hold_for_signup && !item?.enable) ||
              (item?.hold_for_signup && item?.enable)
            ) {
              console.log("removed from the list");
              //  acc.freeProeprtyIDs.push({ ...item, enable: false }); // Add to inActivePremiumProeprtyIDs if hold_for_signup is true
            } else {
              acc.selectedPropertiesforSignup.push(item);
              acc.premiumProeprtyIDs.push({
                id: item?.id,
                property_id: item?.property_id,
                enable: item?.enable,
              }); // Add to activePremiumProeprtyIDs otherwise
            }
            return acc;
          },
          { premiumProeprtyIDs: [], selectedPropertiesforSignup: [] } // Initialize with empty arrays
        );
      premiumSubscriptionProperties = premiumProeprtyIDs;
      dispatch(
        propertyRiskManagementAction.selectedPropertiesForPremiumMonitoring(
          selectedPropertiesforSignup
        )
      );
    }

    const data = {
      free_monitoing: freeMonitoringProperties,
      premium_subscription: premiumSubscriptionProperties,
    };

    dispatch(propertyRiskManagementAction.selectedPropertiesData(data));

    if (
      !investorReportResultData?.data?.phone &&
      (data?.free_monitoing?.length > 0 ||
        data?.premium_subscription?.length > 0)
    ) {
      setIsSelectePropertyModal(true);
    } else {
      if (
        data?.free_monitoing?.length > 0 ||
        data?.premium_subscription?.length > 0
      ) {
        let URL = `update-investor-properties-status/${investorReportResultData?.data?.id}`;
        dispatch(
          propertyRiskManagementAction.getInvestorsPropertyServiceData({
            url: URL,
            data: data,
          })
        );
      }
    }
  };

  const closeInvestorMonitoringDataModal = () => {
    if (investorProeprtyMonitoringData?.success) {
      if (selectedPropertiesData?.premium_subscription?.length > 0) {
        clearLocalStoarge();
        localStorage.setItem("investor_id", investorReportResultData?.data?.id);
        if (investorReportResultData?.data?.user?.json_last_step?.step > 0) {
          navigate("/login");
        } else {
          navigate("/create-account");
        }
        setSelectedPremiumProperties([]);
      }
      setSelectedProperties([]);
    }
    dispatch(propertyRiskManagementAction.selectedPropertiesData());
    dispatch(propertyRiskManagementAction.fetchInvestorsPropertyServiceData());
  };

  const closeInvestorsModal = () => {
    dispatch(propertyRiskManagementAction.fetchInvestorReportResultData({}));
    setInValidUUID(false);
    stopTimer();
    navigate(`/`);
  };

  // Function to dynamically generate per page options
  const getPerPageOptions = (count) => {
    const options = [];
    for (let i = 10; i <= count + 10; i += 10) {
      options.push(i);
    }
    return options;
  };

  const handlePaginationFilterOption = (e) => {
    console.log(e.target.value);
    setLimit(parseInt(e.target.value));
    setOffset(0);
    setPage(1);
    setPayload((prevState) => ({
      ...prevState,
      limit: parseInt(e.target.value),
      offset: 0,
    }));
    // setSelectedPremiumProperties([]);
    // setSelectedProperties([]);
    setPageCount(Math.ceil(investorReportResultData?.count / e.target.value));
  };

  useEffect(() => {
    if (getObjLength(investorReportResultData)) {
      if (investorReportResultData?.success) {
        setPerPageOptions(getPerPageOptions(investorReportResultData?.count));
        if (pageCount === 0)
          setPageCount(Math.ceil(investorReportResultData?.count / PER_PAGE));
      }
      if (!investorReportResultData?.success) {
        startTimer(10);
        setInValidUUID(true);
      }
    }
  }, [investorReportResultData]);

  useEffect(() => {
    if (
      getObjLength(investorReportResultData) &&
      !investorReportResultData?.success &&
      timer === 0
    ) {
      closeInvestorsModal();
    }
  }, [timer]);

  useEffect(() => {
    if (getObjLength(investorMonitoringData)) {
      closeModal();
      if (
        investorMonitoringData?.success &&
        optionType === "premium_monitoring"
      ) {
        ListAPIcall();
      }
    }
  }, [investorMonitoringData]);

  useEffect(() => {
    if (getObjLength(investorProeprtyMonitoringData)) {
      if (investorProeprtyMonitoringData?.success) {
        ListAPIcall();
      }
      closeModal();
    }
  }, [investorProeprtyMonitoringData]);

  if (!getObjLength(investorReportResultData)) {
    return <Loader open={true} />;
  }

  return (
    <div className="investor-list">
      <Stack
        className={`container-spacing header_content`}
        direction={"row"}
        spacing={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Link to={"/"} className="EP_logo">
          <Box className="flex-logos">
            {/* <LazyLoadImage
              src={logo_ipsum}
              width="120px"
              height="100%"
              alt="logo"
            />
            <div className="saperator"></div> */}
            <div className="d-flex align-items-center gap-2">
              {/* <p className="by-text m-0"> BY </p> */}
              <LazyLoadImage
                src={logo_blk}
                width="200px"
                height="100%"
                alt="logo"
              />
            </div>
          </Box>
        </Link>
        <Box className="contact_section">
          <div className="d-none d-md-block">
            <Typography className="help_texts">Need help?</Typography>
            <Typography className="contact_texts">
              Call us at:{" "}
              <Callto phone={`${SUPPORT_PHONE}`}>
                <span className="phone_number">{SUPPORT_PHONE_FORMAT}</span>
              </Callto>
            </Typography>
          </div>
          <div className="MobAction_btn ms-auto d-flex flex-row d-md-none">
            <CallUsNow
              hideEmail={true}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              renderText={
                <Button className="mx-1 call_btn" variant="link">
                  <LazyLoadImage
                    src={phoneTalk}
                    width="24px"
                    height="24px"
                    alt="Phone Talk"
                  />
                </Button>
              }
            />
          </div>
        </Box>
      </Stack>
      <Box className="report-results-investor">
        <section className={`main_section`}>
          <Container fluid>
            <Grid container alignItems="center" className="summary-card">
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={6}
                //   className="mt-2"
                className="left-section"
                order={{ xs: 2, sm: 2, md: 1 }}
              >
                <Box className="section1">
                  <Typography
                    variant="h6"
                    className="personal_info text-capitalize mb-2"
                  >
                    Summary
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className=""
                  >
                    <Typography variant="h5" className="investor-name">
                      Hi, {investoraData?.full_name || "there!"}
                    </Typography>
                    <Box className="property-stats">
                      <Stack
                        direction={"row"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="property-count"
                      >
                        <div className="pe-2 pl-1 d-flex align-items-center">
                          <img
                            src={HolidayVillage}
                            alt="HolidayVillage"
                            className="pe-2 pl-0"
                          />
                          {investorReportResultData?.count
                            ? investorReportResultData?.count > 1
                              ? `${investorReportResultData?.count} Properties`
                              : `${investorReportResultData?.count} Property`
                            : "Your Properties"}
                        </div>
                        {/* <div className="px-2 border-left">
                          <img src={Contract} alt="Contract" className="px-2" />
                          24 Records
                        </div> */}
                      </Stack>
                    </Box>
                  </Stack>
                  <Typography variant="body1" className="description py-2">
                    We have identified the following properties in your
                    portfolio. Using our advanced, proprietary system, we have
                    conducted a thorough risk analysis on each one and assigned
                    a corresponding risk level to help you understand potential
                    vulnerabilities.
                  </Typography>

                  <Box className="alert-section pt-3">
                    {parseInt(investoraData?.high_count) > 0 && (
                      <Button disabled className="alert critical-alert">
                        <ErrorOutlineIcon />
                        &nbsp;{investoraData?.high_count || 0} Critical Alerts
                      </Button>
                    )}
                    {parseInt(investoraData?.medium_high_count) +
                      parseInt(investoraData?.medium_count) >
                      0 && (
                      <Button disabled className="alert medium-alert">
                        <ErrorOutlineIcon />
                        &nbsp;
                        {investoraData?.medium_count +
                          investoraData?.medium_high_count || 0}{" "}
                        Medium Alerts
                      </Button>
                    )}
                    {parseInt(investoraData?.low_count) > 0 && (
                      <Button disabled className="alert medium-low-alert">
                        <ErrorOutlineIcon />
                        &nbsp;{investoraData?.low_count || 0} Low Alerts
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="below_divider"
                order={{ xs: 3, sm: 3 }}
              >
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={3.5}
                xl={3}
                className="border-left middle-section"
                order={{ xs: 5, sm: 5, md: 4 }}
              >
                <Box className="chart-section d-flex justify-content-center align-items-center h-75">
                  <RiskoMeterChart chart_status={investoraData?.risk_level} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={2.5}
                xl={3}
                className="right-section"
                order={{ xs: 4, sm: 4, md: 4 }}
              >
                <Box className="property-details position-relative text-center">
                  <Typography variant="body1" className="value_text">
                    All Properties Value
                  </Typography>
                  <Typography className="approx_price">
                    {`${currencyFormatter(investoraData?.property_value || 0)}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_2">
          <Container fluid className="position-relative">
            <Grid
              container
              className="title-monitoring-section position-relative overflow-hidden d-flex justify-content-center align-items-center"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={7}
                xl={7}
                className="grid2"
              >
                <EpOrbitTransitionSection />
              </Grid>
              <Grid item xs={12} md={12} lg={5} xl={5} className="grid1">
                <div>
                  <h4 className="monitoring_essential">
                    About EquityProtect and the data we’re providing
                  </h4>
                  <p className="card-text mt-2">
                    EquityProtect monitors public property records for
                    suspicious activity, such as unauthorized title changes,
                    liens, or ownership updates. Our free monitoring service
                    covers low to medium-risk properties—comparable plans
                    elsewhere cost over $200.
                  </p>
                  <p className="card-text mt-2">
                    For higher-risk properties, we recommend our full protection
                    plan, which not only detects fraud but also prevents it and
                    resolves claims swiftly. With cutting-edge technology and
                    expert support, we help property owners protect their
                    investments and avoid financial losses from title fraud.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Box>
      {/* <Container> */}
      <div className="container-spacing">
        <Box className="top_section">
          <Box className="heading_text ">
            <Typography className="heading">Your properties</Typography>
            {/* <Typography className="sub_heading">
              Lorem ipsum dolor sit amet consectetur. Lacus facilisis tortor non
              lorem adipiscing mattis enim nibh sit. Libero est id orci{" "}
            </Typography> */}
          </Box>
          {getObjLength(investorReportResultData) &&
            investorReportResultData?.success &&
            investorReportResultData?.count > 9 && (
              <Box className="filter-property">
                <Card className="filter-options-carddiv">
                  <Grid container className="filter-options-box">
                    <Grid item>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <FormControl className="filter-select-one bg-white">
                          <TextField
                            id="outlined-select-currency"
                            select
                            defaultValue={10}
                            //  value={offset}
                            onChange={(e) => {
                              handlePaginationFilterOption(e);
                            }}
                          >
                            {perPageOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option} per page
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            )}
        </Box>
        <Box>
          {!inValidUUID ? (
            <>
              <ListTable
                setSelectedProperties={setSelectedProperties}
                selectedProperties={selectedProperties}
                setSelectedPremiumProperties={setSelectedPremiumProperties}
                selectedPremiumProperties={selectedPremiumProperties}
                ownerName={investorReportResultData?.data?.full_name}
                userData={investorReportResultData?.data?.user}
                investorEmail={investorReportResultData?.data?.email}
                rows={investorReportResultData?.data?.properties}
                isActionButton={false}
                loader={investorReportResultsLoader}
                page={
                  payload?.limit > 10 ? (payload?.limit / 10) * offset : offset
                }
                payload={payload}
              />
              <Box
                className="mt-5" //investor-pagination
                id="pagination-container"
              >
                {pageCount > 1 && (
                  <CustomPagination
                    count={pageCount}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                )}
              </Box>
            </>
          ) : (
            // <></>
            <AlertDialog
              type={"Error"}
              openPopup={true}
              onClose={true}
              onCloseFun={closeInvestorsModal}
              mesage={
                <p>
                  {investorReportResultData?.data?.message ||
                    "Something went wrong"}
                  <br />
                  <span>
                    You will be taken to the homepage shortly{" "}
                    <b>({formatTimeForResendOTP(timer)})</b>. For additional
                    assistance, kindly contact our support team.
                  </span>
                </p>
              }
            />
          )}
          <Box className="save">
            <Button className="save-btn" onClick={() => handleSaveChange()}>
              Save changes
            </Button>
          </Box>
        </Box>
      </div>

      {isSelectePropertyModal && (
        <DialogBox
          width="lg"
          style={{ backgroundColor: "#f6f9fc" }}
          contentClassName={""}
          title={<p className="me-5">Add Your details</p>}
          openPopup={isSelectePropertyModal}
          onClose={() => {
            dispatch(propertyRiskManagementAction.resetInvestorFormDetails({}));
            closeModal();
          }}
          close
          content={
            <>
              <Box className="my-2 ">
                <FreeMonitoringUserForm
                  handleClose={closeModal}
                  owner_name={investoraData?.full_name}
                  inv_email={investoraData?.email}
                  classes={"pt-4"}
                  optionType={optionType}
                  from={"investors"}
                  buttonText="Add Details"
                />
              </Box>
            </>
          }
        />
      )}

      {getObjLength(investorProeprtyMonitoringData) ? (
        <AlertDialog
          type={investorProeprtyMonitoringData?.success ? "Success" : "Error"}
          openPopup={true}
          onClose={true}
          onCloseFun={closeInvestorMonitoringDataModal}
          mesage={
            investorProeprtyMonitoringData?.success
              ? investorProeprtyMonitoringData?.message
              : investorProeprtyMonitoringData?.data?.message ||
                "Something went wrong"
          }
        />
      ) : null}

      <Loader open={investorReportResultsLoader} />
    </div>
  );
};

export default InvestorList;
