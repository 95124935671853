import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Nav from "react-bootstrap/Nav";
import { useTheme } from "@emotion/react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

import CallUsNow from "../common/CallUsNow";
import { ROLES } from "../helpers/constants";
import logo from "../../assets/logo/equity.WebP";
import logo_blk from "../../assets/logo/ep_new.svg";
import phoneTalk from "../../assets/phone_in_talk.svg";
import userAccount from "../../assets/account_circle.svg";
import {
  decryptPayload,
  encryptPayload,
  getObjLength,
  navigateToCustomerDomain,
} from "../helpers/utils";
import { propertyRiskManagementAction } from "../../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";

const NavBar = ({
  roles = [],
  isLandingPage,
  stickyNavbar = false,
  hideServiceProviderFromNavbar = false,
  isAttorney = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1410.98));
  const showNavbarAccordian = useMediaQuery(theme.breakpoints.down(991.98));
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const pathname = window.location.pathname;

  const queryString = window.location.search;
  let paramString = queryString.split("?")[1];
  let valueOf = paramString?.split("=")[0];

  let is_scrp_hp = localStorage.getItem("scrp_hp");
  let countyCode = decryptPayload(localStorage.getItem("county-code"));
  const { navbarColored } = useSelector(
    (state) => state?.propertyRiskManagement
  );
  // store data
  const { riskManagementData } = useSelector(
    (state) => state?.propertyRiskManagement
  );

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const maangeCountyData = (county_details) => {
    if (getObjLength(county_details)) {
      is_scrp_hp = 1;
      countyCode = encryptPayload(county_details?.county_domain_name);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const headerNavbar = document.getElementById("HeaderNavbar");
      const firstSectionHeight = window.innerHeight; // Assuming 100vh for the first section
      const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

      if (headerNavbar?.classList) {
        if (
          window.location.href.includes("privacy") ||
          window.location.href.includes("terms") ||
          window.location.href.includes("property-risk-report") ||
          window.location.href.includes("sitemap")
        ) {
          headerNavbar.classList.add("sticky");
          dispatch(propertyRiskManagementAction.setNavbarColor(true));
        } else {
          const scrollThreshold = isTablet
            ? firstSectionHeight / 20
            : firstSectionHeight / 12;
          if (window.scrollY > scrollThreshold) {
            headerNavbar.classList.add("sticky");
            dispatch(propertyRiskManagementAction.setNavbarColor(true));
          } else {
            headerNavbar.classList.remove("sticky");
            dispatch(propertyRiskManagementAction.setNavbarColor(false));
          }
        }
      }
    });

    const handleOutsideClick = (event) => {
      // Close the Navbar if a click occurs outside of it
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    // Attach click event listener to the document body
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener on component unmount
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (expanded) {
      handleNavItemClick();
    }
    if (pathname?.includes("property-risk-report")) {
      document.getElementById("HeaderNavbar")?.classList.add("sticky");
      if (
        valueOf === "report_token" &&
        getObjLength(riskManagementData) &&
        riskManagementData?.success
      ) {
        let county_details = riskManagementData?.data?.county;
        maangeCountyData(county_details);
        // set county if it's already available
        if (getObjLength(county_details)) {
          localStorage.setItem("scrp_hp", 1);
          localStorage.setItem(
            "county-code",
            encryptPayload(county_details?.county_domain_name)
          );
        } else {
          localStorage.removeItem("scrp_hp");
          localStorage.removeItem("county-code");
        }
      }
    }
  }, [pathname]);

  // handle My Account Button
  const handleMyAccountBtn = () => {
    console.log("My Account Button clicked");
    let user = localStorage.getItem("authorised");
    let userRole = decryptPayload(localStorage.getItem("roles"));
    if (
      user === "true" &&
      (userRole?.includes(ROLES.admin) ||
        userRole?.includes(ROLES.subAdmin) ||
        (!userRole?.includes(ROLES.serviceProvider) &&
          userRole?.includes(ROLES.provider)) ||
        userRole?.includes(ROLES.countyRecorder))
    ) {
      navigate("/admin-profile");
    } else if (
      user === "true" &&
      (userRole?.includes(ROLES.customer) ||
        userRole?.includes(ROLES.secondaryUser))
    ) {
      navigate("/user-profile");
    } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
      navigate("/servicer-profile");
    } else if (user === "true" && userRole?.includes(ROLES.professional)) {
      navigate("/professional-profile");
    } else {
      navigate("/login");
    }
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };
  // handle login Button
  const handleLoginBtn = () => {
    console.log("login Button clicked");
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
    let user = localStorage.getItem("authorised");
    let userRole = decryptPayload(localStorage.getItem("roles"));
    if (
      user === "true" &&
      (userRole?.includes(ROLES.admin) ||
        userRole?.includes(ROLES.subAdmin) ||
        userRole?.includes(ROLES.provider) ||
        userRole?.includes(ROLES.countyRecorder))
    ) {
      return "/admin-profile";
    } else if (
      user === "true" &&
      (userRole?.includes(ROLES.customer) ||
        userRole?.includes(ROLES.secondaryUser))
    ) {
      return "/user-profile";
    } else if (user === "true" && userRole?.includes(ROLES.serviceProvider)) {
      return "/servicer-profile";
    } else {
      return "/login";
    }
  };

  useEffect(() => {
    const toggle = document.querySelector(".navbar-toggler-icon");
    if (!toggle) return;

    // Update the --toggle-color for all lines, not just the main one
    if (isLandingPage) {
      if (navbarColored) {
        toggle.style.setProperty("--toggle-color", "#ffffff");
      } else {
        toggle.style.setProperty("--toggle-color", "#000000");
      }
      if (expanded) {
        toggle.style.setProperty("--toggle-color", "#ffffff");
      }
    } else {
      toggle.style.setProperty("--toggle-color", "#fff");
    }
  }, [isLandingPage, navbarColored, expanded]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget); // Open the dropdown on hover
  };

  const handleMouseLeave = () => {
    setAnchorEl(null); // Close the dropdown when mouse leaves
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg={
          stickyNavbar
            ? "dark"
            : expanded && isLandingPage
            ? "dark"
            : navbarColored
            ? "dark"
            : "transparent"
        }
        variant="dark"
        className={`py-4 py-md-4 ${stickyNavbar && "reposrtResultNavbar"}`}
        id="HeaderNavbar"
        expanded={expanded}
        ref={navbarRef}
      >
        <Container fluid className="navbar-spacing">
          <Navbar.Toggle
            className={`mobile-menu`}
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          ></Navbar.Toggle>
          <Link
            to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
            className="publicNav_logo"
          >
            <Navbar.Brand className="p-0 ">
              <img
                src={
                  isLandingPage
                    ? expanded
                      ? logo
                      : navbarColored
                      ? logo
                      : logo_blk
                    : logo
                }
                width="250px"
                height="100%"
                alt="logo"
              />
              {/* <img
                src={logo}
                alt="logo"
                style={{ width: "250px", height: "100%" }}
              /> */}
            </Navbar.Brand>
          </Link>

          <Nav
            className="MobAction_btn ms-auto public_navbar"
            onSelect={handleNavItemClick}
          >
            <CallUsNow
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              isAttorney={isAttorney}
              renderText={
                <Button
                  className="footer_btn footer_blue mx-1 phone_btn"
                  variant="link"
                >
                  <LazyLoadImage
                    src={phoneTalk}
                    width="24px"
                    height="24px"
                    alt="Phone Talk"
                  />
                </Button>
              }
            />

            {!isAttorney && (
              <Button
                className="footer_btn footer_green mx-1 my_account_btn"
                onClick={handleMyAccountBtn}
                variant="link"
              >
                <LazyLoadImage
                  src={userAccount}
                  width="24px"
                  height="24px"
                  alt="User Account"
                />
                {/* <img
                src={userAccount}
                alt="User Account"
                width="24px"
                height="24px"
              /> */}
              </Button>
            )}
          </Nav>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className={`navitems navbar-center ${
                isLandingPage
                  ? "justify-content-left left-nav-spacing"
                  : "justify-content-center"
              }`}
              onSelect={handleNavItemClick}
            >
              <NavLink
                className={({ isActive }) =>
                  `${
                    isLandingPage
                      ? navbarColored
                        ? "text-white"
                        : expanded
                        ? "text-white"
                        : "text-dark"
                      : "text-white"
                  } text-uppercase mx-0 mx-md-3 mx-6 ${
                    isActive && !isMenuOpen ? "link-active" : ""
                  }`
                }
                style={
                  isLandingPage && !isMenuOpen
                    ? {
                        borderBottom: "2px solid #0155B7",
                        paddingBottom: "5px",
                      }
                    : {}
                }
                to={is_scrp_hp ? `/county-recorder/${countyCode}` : "/"}
              >
                Home
              </NavLink>
              {!roles.includes(ROLES.serviceProvider) && !isAttorney && (
                <NavLink
                  className={({ isActive }) =>
                    `${
                      isLandingPage
                        ? navbarColored
                          ? "text-white"
                          : expanded
                          ? "text-white"
                          : "text-dark"
                        : "text-white"
                    } text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive && !isMenuOpen ? "link-active" : ""
                    }`
                  }
                  to="/how-it-works"
                >
                  How it Works
                </NavLink>
              )}
              {!roles.includes(ROLES.serviceProvider) && !isAttorney && (
                <NavLink
                  className={({ isActive }) =>
                    `${
                      isLandingPage
                        ? navbarColored
                          ? "text-white"
                          : expanded
                          ? "text-white"
                          : "text-dark"
                        : "text-white"
                    } text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive && !isMenuOpen ? "link-active" : ""
                    }`
                  }
                  to={`${is_scrp_hp ? "/" + countyCode : ""}/pricing`}
                >
                  Pricing
                </NavLink>
              )}
              {!roles.includes(ROLES.serviceProvider) && !isAttorney && (
                <>
                  {!showNavbarAccordian ? (
                    <NavLink
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className={({ isActive }) =>
                        `${
                          isLandingPage
                            ? navbarColored
                              ? "text-white"
                              : expanded
                              ? "text-white"
                              : "text-dark"
                            : "text-white"
                        } text-uppercase mx-0 mx-md-3 mx-6 ${
                          isMenuOpen ? "link-active" : ""
                        }`
                      }
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      <span>Title Theft</span>
                      <Menu
                        anchorEl={anchorEl}
                        open={isMenuOpen}
                        onClose={handleMouseLeave}
                        MenuListProps={{
                          onMouseLeave: handleMouseLeave,
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: "4px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          },
                        }}
                        disableScrollLock
                      >
                        <MenuItem
                          className="text-uppercase"
                          onClick={handleMouseLeave}
                        >
                          <Link
                            to="/what-is-home-title-theft"
                            className="navbar-menu-texts"
                          >
                            What is Title Theft?
                          </Link>
                        </MenuItem>
                        <MenuItem
                          className="text-uppercase"
                          onClick={handleMouseLeave}
                        >
                          <Link
                            to="/title-protection-long-time-homeowners"
                            className="navbar-menu-texts"
                          >
                            Risks for Homeowners
                          </Link>
                        </MenuItem>
                        <MenuItem
                          className="text-uppercase"
                          onClick={handleMouseLeave}
                        >
                          {" "}
                          <Link
                            to="/title-protection-real-estate-investors"
                            className="navbar-menu-texts"
                          >
                            Risks for Real Estate Investors{" "}
                          </Link>
                        </MenuItem>
                      </Menu>
                    </NavLink>
                  ) : (
                    //Collapsible Menu for Title Theft
                    <Accordion className="mobile-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="title-theft-content"
                        id="title-theft-header"
                      >
                        <span className="text-uppercase">Title Theft</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <NavLink
                          to="/what-is-home-title-theft"
                          className="text-uppercase my-1"
                        >
                          What is Title Theft?
                        </NavLink>
                        <NavLink
                          to="title-protection-long-time-homeowners"
                          className="text-uppercase my-1"
                        >
                          Risks for Homeowners
                        </NavLink>
                        <NavLink
                          to="/title-protection-real-estate-investors"
                          className="text-uppercase my-1"
                        >
                          Risks for Real Estate Investors
                        </NavLink>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )}
              {roles.includes(ROLES.serviceProvider) && (
                <NavLink
                  className={() =>
                    `${
                      isLandingPage
                        ? navbarColored
                          ? "text-white"
                          : expanded
                          ? "text-white"
                          : "text-dark"
                        : "text-white"
                    } text-uppercase mx-0 mx-md-3 mx-6 
                    }`
                  }
                  onClick={() => navigateToCustomerDomain("")}
                >
                  For Customer
                </NavLink>
              )}
              {!is_scrp_hp && !isLandingPage ? (
                <NavLink
                  className={({ isActive }) =>
                    `${
                      isLandingPage
                        ? navbarColored
                          ? "text-white"
                          : expanded
                          ? "text-white"
                          : "text-dark"
                        : "text-white"
                    } text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive && !isMenuOpen ? "link-active" : ""
                    }`
                  }
                  to="/for-service-provider"
                >
                  For Service Providers
                </NavLink>
              ) : isMobile && !isAttorney ? (
                <NavLink
                  className={({ isActive }) =>
                    `${
                      isLandingPage
                        ? navbarColored
                          ? "text-white"
                          : expanded
                          ? "text-white"
                          : "text-dark"
                        : "text-white"
                    } text-uppercase mx-0 mx-md-3 mx-6 ${
                      isActive && !isMenuOpen ? "link-active" : ""
                    }`
                  }
                  to="/for-service-provider"
                >
                  For Service Providers
                </NavLink>
              ) : null}
              {!isAttorney && (
                <span onClick={handleMyAccountBtn}>
                  <NavLink
                    className={() =>
                      `${
                        isLandingPage
                          ? navbarColored
                            ? "text-white"
                            : expanded
                            ? "text-white"
                            : "text-dark"
                          : "text-white"
                      } text-uppercase mx-0 mx-md-3 mx-6 active-on-hover d-block d-lg-none`
                    }
                    to="#"
                  >
                    Login
                  </NavLink>
                </span>
              )}
              {/* <NavLink
                className={({ isActive }) =>
                  `text-white text-uppercase mx-0 mx-md-3 mx-6 ${
                    isActive ? "link-active" : ""
                  }`
                }
                to="/lead"
              >
                Lead
              </NavLink> */}
              {/* <Link
                className="text-white text-uppercase mx-0 px-md-4 px-6"
                to="/faq-articles"
              >
                FAQ / Articles
              </Link> */}
            </Nav>
            <Nav
              className={`navitems action_btn mt-3 mt-md-0 right-nav-spacing`}
            >
              {!is_scrp_hp &&
                isLandingPage &&
                !isMobile &&
                !isAttorney &&
                !hideServiceProviderFromNavbar && (
                  <NavLink
                    className={({ isActive }) =>
                      `${
                        isLandingPage && navbarColored
                          ? "text-white"
                          : "text-dark"
                      } text-uppercase mx-0 mx-md-3 mx-6 ${
                        isActive && !isMenuOpen ? "link-active" : ""
                      }`
                    }
                    to="/for-service-provider"
                  >
                    For Service Providers
                  </NavLink>
                )}
              <CallUsNow
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                type={isAttorney ? "attorney" : "text"}
              />
              {!isAttorney && (
                <Button
                  className="footer_btn footer_green my-2 my-lg-0 mx-0 mx-lg-1"
                  variant="link"
                  onClick={handleMyAccountBtn}
                >
                  My Account
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
