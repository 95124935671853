import { useState } from "react";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useStripe } from "@stripe/react-stripe-js";

import { Loader } from "./loader";
import AlertDialog from "./AlertDialog";
import { getObjLength } from "../helpers/utils";
import CardList from "../userProfile/OrderDocuments/CradList";
import { subscriptionAction } from "../../redux/slices/subscription/subscriptionSlice";

const SecurePaymentPanel = ({
  displayLabel,
  cardData,
  paymentData,
  onSuccessFun = () => {},
  onFailedFun = () => {},
  userId,
  cardList = true,
  successMsg,
  successClose = true,
  last_steps = {},
  is_renew = false,
  // for promocoed
  addPromoCode = false,
  promoCodeValue = "",
  paySecure = () => {},
  // fro add properties
  from = "",
  uid = null,
  callListingAPI = () => {},
  setTotalAmountModal,
  isUserCreateProperty = false,
  callListAPI = false,
  clearOrderDoc = true,
  path = null,
  onClose = () => {},
  isOpenFromBottom = false,
  setValdiateModal = () => {},
  setValidation3DMsg = () => {},
  propertiesIds = undefined,
  selectedData = {},
}) => {
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [validationMsg, setValidationMsg] = useState("");

  const stripe = useStripe();
  const dispatch = useDispatch();

  const { threedSecurePayment, threedSecurePaymentLoader } = useSelector(
    (state) => state?.subscription
  );
  const { userProfileDetails } = useSelector((state) => state?.userProfile);
  const { servicerProfileDetails } = useSelector(
    (state) => state?.servicerProfile
  );

  console.log("paymentIntent -", paymentData);
  const clientSecret = async () => {
    const { paymentIntent, error } = await stripe.confirmCardPayment(
      paymentData?.data?.clientSecret,
      { payment_method: paymentData?.data?.card }
    );
    console.log("paymentIntent", paymentIntent);
    let isPaymnetDone = "";
    if (getObjLength(paymentIntent)) {
      isPaymnetDone = true;
    }
    if (getObjLength(error)) {
      isPaymnetDone = false;
      setValidationMsg(error?.message);
      setValidation3DMsg(error?.message);
    }

    let finaldata = {
      json_res_ids: paymentData?.data?.json_res_ids,
      isPaymnetDone: isPaymnetDone,
      user_id:
        userId ||
        userProfileDetails?.data?.user_id ||
        servicerProfileDetails?.data?.user_id,
      is_renew: is_renew,
    };

    if (getObjLength(last_steps)) {
      finaldata = { ...finaldata, ...last_steps };
    }

    if (
      paymentData?.data?.doc_payment_id !== null &&
      paymentData?.data?.doc_request_id !== null
    ) {
      finaldata = {
        ...finaldata,
        doc_payment_id: paymentData?.data?.doc_payment_id,
        doc_request_id: paymentData?.data?.doc_request_id,
      };
    }
    console.log("final data=>", finaldata);
    let URL = "complete-payment";
    if (path) {
      URL = `providers/${path}/complete-payment`;
    }
    dispatch(
      subscriptionAction.get3dSecurePaymentData({
        url: URL,
        data: finaldata,
      })
    );
    setPaymentLoader(false);
    console.log("paymentIntent", paymentIntent);
    console.log("paymentIntent error", error);
  };

  const onSuccessModal = () => {
    onSuccessFun();
    if (clearOrderDoc) {
      dispatch(subscriptionAction.clear3dSecurePaymentData({}));
    }
  };

  const onFailedModal = () => {
    if (callListAPI) {
      callListingAPI();
    }
    onFailedFun();
    localStorage.setItem("paymentPopup", 0);
    dispatch(subscriptionAction.clear3dSecurePaymentData({}));
  };

  useEffect(() => {
    if (paymentData?.success) {
      if (paymentData?.data?.actionRequired) {
        setPaymentLoader(true);
        console.log("stripe==>", stripe);
        console.log("paymentIntent --", paymentData);
        clientSecret();
      }
    }
  }, [paymentData]);

  useEffect(() => {
    if (getObjLength(threedSecurePayment)) {
      setValdiateModal(true);
    }
  }, [threedSecurePayment]);
  console.log(from, "===paymentData==> ", paymentData);
  return (
    <>
      {cardList &&
        (from === "add-subscriptions" ||
        from === "add-property" ||
        {
          /* from === "property-renewal " */
        } ? (
          <CardList
            displayLabel={false}
            cardData={cardData}
            userId={userId}
            from={from}
            addPromoCode={addPromoCode}
            promoCodeValue={promoCodeValue}
            callListingAPI={callListingAPI}
            paySecure={paySecure}
            // for add properties
            uid={uid}
            setTotalAmountModal={setTotalAmountModal}
            isUserCreateProperty={isUserCreateProperty}
            isOpenFromBottom={isOpenFromBottom}
            propertiesIds={propertiesIds}
            selectedData={selectedData}
          />
        ) : (
          <CardList
            displayLabel={false}
            cardData={cardData}
            // for promocode
            addPromoCode={addPromoCode}
            promoCodeValue={promoCodeValue}
            paySecure={paySecure}
            isUserCreateProperty={isUserCreateProperty}
            isOpenFromBottom={isOpenFromBottom}
            propertiesIds={propertiesIds}
            selectedData={selectedData}
          />
        ))}
      <Loader open={threedSecurePaymentLoader || paymentLoader} />

      {getObjLength(threedSecurePayment) ? (
        threedSecurePayment?.success ? (
          <AlertDialog
            type="Success"
            openPopup={true}
            onClose={successClose}
            onCloseFun={onSuccessModal}
            mesage={successMsg ? successMsg : threedSecurePayment?.message}
            footer={false}
          />
        ) : (
          <AlertDialog
            type="Error"
            openPopup={true}
            onClose={true}
            onCloseFun={onFailedModal}
            mesage={
              validationMsg?.length > 0
                ? validationMsg
                : threedSecurePayment?.data?.message
            }
            footer={false}
          />
        )
      ) : null}
    </>
  );
};

export default SecurePaymentPanel;
