import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Loader } from "./loader";
import { LOCAL_RECORDING_FEES } from "../helpers/constants";
import { currencyFormatter, getObjLength } from "../helpers/utils";
import auto_activity1 from "../../assets/home/auto_activity_zone.svg";
import auto_activity from "../../assets/home/simple_auto_activity_zone .svg";

const SubscriptionPlansContent = ({
  plan1 = true,
  plan2 = true,
  fullPage = false,
  price,
  county_name = null,
  promoCode = null,
}) => {
  const navigate = useNavigate();
  const { subscriptionPlans, statesLoader } = useSelector(
    (state) => state.signUpUser
  );

  const [MONTHLY_PLAN, set_MONTHLY_PLAN] = useState();
  const [MONTHLY_PLAN_AMOUNT, set_MONTHLY_PLAN_AMOUNT] = useState();
  const [MONTHLY_DISCOUNT_PLAN_AMOUNT, set_MONTHLY_DISCOUNT_PLAN_AMOUNT] =
    useState();
  const [MONTHLY_DISCOUNT_SETUP_AMOUNT, set_MONTHLY_DISCOUNT_SETUP_AMOUNT] =
    useState();
  const [MONTHLY_PLAN_RECORDING_FEES, set_MONTHLY_PLAN_RECORDING_FEES] =
    useState();
  const [MONTHLY_PLAN_PER_MONTH, set_MONTHLY_PLAN_PER_MONTH] = useState();
  const [YEARLY_PLAN, set_YEARLY_PLAN] = useState();
  const [YEARLY_PLAN_PER_MONTH, set_YEARLY_PLAN_PER_MONTH] = useState();
  const [YEARLY_PLAN_RECORDING_FEES, set_YEARLY_PLAN_RECORDING_FEES] =
    useState();
  const [YEARLY_DISCOUNT_PLAN_AMOUNT, set_YEARLY_DISCOUNT_PLAN_AMOUNT] =
    useState();
  const [YEARLY_SAVINGS, set_YEARLY_SAVINGS] = useState();
  const [FIRST_YEAR_SAVINGS, set_FIRST_YEAR_SAVINGS] = useState();

  useEffect(() => {
    if (getObjLength(subscriptionPlans) && subscriptionPlans?.success) {
      if ((subscriptionPlans?.data).length > 0) {
        const monthlyPlans = subscriptionPlans?.data?.find(
          (data) => data?.name === "Monthly"
        );
        const yearlyPlans = subscriptionPlans?.data?.find(
          (data) => data?.name === "Yearly"
        );
        set_MONTHLY_PLAN(monthlyPlans?.setup_fees);
        set_MONTHLY_PLAN_AMOUNT(monthlyPlans?.amount);
        set_MONTHLY_DISCOUNT_PLAN_AMOUNT(monthlyPlans?.subscription_discount);
        set_MONTHLY_DISCOUNT_SETUP_AMOUNT(monthlyPlans?.setup_fee_discount);
        set_MONTHLY_PLAN_PER_MONTH(monthlyPlans.recurring_amount);
        set_MONTHLY_PLAN_RECORDING_FEES(monthlyPlans.recording_fee);
        set_YEARLY_PLAN(yearlyPlans?.amount);
        set_YEARLY_DISCOUNT_PLAN_AMOUNT(yearlyPlans?.subscription_discount);
        set_YEARLY_PLAN_PER_MONTH(yearlyPlans?.recurring_amount);
        set_YEARLY_SAVINGS(yearlyPlans?.yearly_savings);
        set_FIRST_YEAR_SAVINGS(yearlyPlans?.first_year_savings);
        set_YEARLY_PLAN_RECORDING_FEES(yearlyPlans?.recording_fee);
      }
    }
  }, [subscriptionPlans]);

  return (
    <>
      {plan1 && (
        <div className="card_inner w-100 break-word">
          <div className="d-flex align-items-center mb-4">
            <div
              className="d-flex align-items-start"
              style={{ flexDirection: "column" }}
            >
              <p className="title mb-0">EquityProtect</p>
              <h2 className="subTitle mb-0">Monthly</h2>
            </div>
            <div className="ms-auto">
              <LazyLoadImage
                src={auto_activity}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              />
              {/* <img
                src={auto_activity}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              /> */}
            </div>
          </div>
          <hr className="seperator" />
          <div className="pricing_Wrapper text-center mt-5">
            {MONTHLY_DISCOUNT_SETUP_AMOUNT ? (
              <h4
                className={`flex-container ${fullPage ? "mb-1" : "mb-2 pt-3"}`}
              >
                <span className="text_strike pe-3">${MONTHLY_PLAN}</span>
                <span>${MONTHLY_DISCOUNT_SETUP_AMOUNT.toFixed(2)}</span>
              </h4>
            ) : (
              <h4 className={`${fullPage ? "mb-1" : "mb-2 pt-3"}`}>
                $
                {county_name
                  ? (MONTHLY_PLAN_AMOUNT / 100).toFixed(2)
                  : MONTHLY_PLAN}
              </h4>
            )}
            <p className="mb-0">
              {county_name ? "for the first Month" : "Set-up & Activation Fee"}
            </p>
            {MONTHLY_DISCOUNT_PLAN_AMOUNT ? (
              <>
                <h5 className="mb-0 pt-4">
                  + ${(MONTHLY_DISCOUNT_PLAN_AMOUNT / 100).toFixed(2)} / month
                </h5>
                <p>for the first Year</p>
                <h6 className="mt-3 flex-container">
                  <span className="pe-3 fw-700">
                    + ${(MONTHLY_PLAN_PER_MONTH / 100).toFixed(2)} / month after
                    a year
                  </span>
                </h6>
              </>
            ) : (
              <h5 className="mt-3">
                + ${(MONTHLY_PLAN_PER_MONTH / 100).toFixed(2)} / month
              </h5>
            )}
            <p className="mb-0 mt-0">+ applicable sales tax</p>
            <p
              className={`${
                fullPage
                  ? "mb-0"
                  : MONTHLY_DISCOUNT_PLAN_AMOUNT
                  ? "mb-2 pb-2"
                  : "mb-4 pb-5"
              }`}
            >
              {/* + <b>{currencyFormatter(LOCAL_RECORDING_FEES)}</b>&nbsp; */}
              {MONTHLY_PLAN_RECORDING_FEES &&
                "+ applicable Deposit for recording fee (One time fee)"}
            </p>
            {/* <h5 className={""}>${MONTHLY_PLAN}</h5>
            <p className="mb-0">Set-up & Activation Fee</p> */}
            {fullPage && (
              <p
                className="pt-1 mt-2"
                style={{
                  color: "transparent",
                  "-webkit-user-select": "none",
                  "-ms-user-select": "none",
                  "user-select": "none",
                }}
              >
                Savings of ${FIRST_YEAR_SAVINGS} for the first Year and $
                {YEARLY_SAVINGS} every year after.
              </p>
            )}
          </div>

          {!fullPage && (
            <div className="text-center mt-auto">
              <button
                className={`signin_button protection_bg w-100 px-2 ${
                  county_name ? "mt-3" : "mt-5"
                }`}
                onClick={() => {
                  if (promoCode) {
                    navigate(`/create-account?code=${promoCode}`);
                  } else {
                    navigate("/create-account");
                  }
                }}
              >
                GET PROTECTION NOW
              </button>
            </div>
          )}
        </div>
      )}

      {plan2 && (
        <div className="card_inner w-100 break-word">
          <div className="d-flex align-items-center mb-4">
            <div
              className="d-flex align-items-start"
              style={{ flexDirection: "column" }}
            >
              <p className="title mb-0">EquityProtect</p>
              <h2 className="subTitle mb-0">Yearly</h2>
            </div>
            <div className="ms-auto">
              <LazyLoadImage
                src={auto_activity1}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              />
              {/* <img
                src={auto_activity1}
                alt="auto_activity_zone"
                width="48px"
                height="48px"
              /> */}
            </div>
          </div>
          <hr className="seperator" />
          <div className="pricing_Wrapper text-center pt-1">
            <p className="mb-0 activation_text">No Set-up or Activation Fee</p>
            {YEARLY_DISCOUNT_PLAN_AMOUNT ? (
              <>
                <h4 className="mb-1 flex-container text-center">
                  <span className="text_strike pe-3">
                    ${((YEARLY_PLAN || price) / 100).toFixed(2)}
                  </span>
                  <span>${(YEARLY_DISCOUNT_PLAN_AMOUNT / 100).toFixed(2)}</span>
                </h4>
              </>
            ) : (
              <h4 className="mb-1">
                ${((YEARLY_PLAN || price) / 100).toFixed(2)}
              </h4>
            )}
            <p className="mb-0">for the first Year</p>
            <h5 className="mt-3">
              + ${(YEARLY_PLAN_PER_MONTH / 100).toFixed(2)} / year{" "}
            </h5>
            <p className="mb-0">per year for annual recertification</p>

            <p className="mb-0 mt-0">+ applicable sales tax</p>
            <p className="mb-0 w-100">
              {/*+ <b>{currencyFormatter(LOCAL_RECORDING_FEES)}</b> &nbsp;*/}
              {YEARLY_PLAN_RECORDING_FEES &&
                "  applicable Deposit for recording fee (One time fee)  "}
            </p>
            <p className="activation_text pt-1 pb-2">
              {!county_name &&
                `Savings of ${FIRST_YEAR_SAVINGS} for the first Year and ${YEARLY_SAVINGS} every year after.`}
            </p>
          </div>
          {!fullPage && (
            <div className="text-center mt-auto">
              <button
                className="signin_button protection_bg mt-4 w-100 px-2"
                onClick={() => {
                  if (promoCode) {
                    navigate(`/create-account?code=${promoCode}`);
                  } else {
                    navigate("/create-account");
                  }
                }}
              >
                GET PROTECTION NOW
              </button>
            </div>
          )}
        </div>
      )}
      <Loader open={statesLoader} />
    </>
  );
};

export default SubscriptionPlansContent;
