import React from "react";
import { useState } from "react";

import Slider from "react-slick";
import { Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { blog_stories } from "../Blogs";
import ReadMoreText from "./ReadMoreText";
import home1 from "../../assets/Rectangle-12.WebP";
import home2 from "../../assets/Rectangle 14.WebP";
import home3 from "../../assets/Rectangle 17.WebP";
import home4 from "../../assets/Rectangle 18.WebP";
import home7 from "../../assets/Rectangle 1381.webp";
import home5 from "../../assets/articles/Rectangle 1379.WebP";
import home6 from "../../assets/articles/Rectangle 1380.WebP";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/realStories.scss";

const articleLink =
  "https://www.greenfieldreporter.com/2024/06/07/county-officials-have-approved-a-property-fraud-measure-to-help-home-and-land-owners/";
const article1Link =
  "https://ktar.com/story/5212228/stealing-home-dozens-of-arizona-houses-fraudulently-sold-without-owners-knowledge/";
const article2Link =
  "https://spectrumnews1.com/ca/la-west/crime/2022/07/23/3-la-homeowners-warn-against-deed-theft-after-properties-are-stolen-by-man";
const article3Link =
  "https://www.wfaa.com/article/news/local/investigates/north-texas-deed-fraud-defendant-flees-hearing-after-forged-signatures-detailed-in-testimony/287-868aaa26-3ad3-4c23-8cba-f25ae6e87761";
const article4Link =
  "https://www.nbcphiladelphia.com/news/local/stolen-homes-forged-deeds-william-johnson-philadelphia/1663/";
const article5Link =
  "https://www.americanbanker.com/news/bank-execs-say-cybersecurity-is-the-biggest-obstacle-to-digital-banking";
const article6Link =
  "https://www.wfaa.com/article/news/local/investigates/new-dirty-deeds-texas-law-allows-clerks-statewide-demand-photo-id-property-transfers/287-5e8f48fb-c676-4a2d-8ed6-3dadd6d8a5f5";

const stories = [
  {
    image: home4,
    title: `Stolen Homes: A Philadelphia Housing Scam on the Rise`,
    description: `Last year, the number of people in Philadelphia who claimed to be victims of deed fraud jumped by more than 70 percent.`,
    link: article4Link,
  },
  {
    image: home5,
    title: `County officials have approved a property fraud measure to help home and
        land owners - The Daily Reporter - Greenfield Indiana`,
    description: `The Hancock County Commissioners approved the contract agreement with a
        company called Equity Protect, at no cost to the county, during a recent
        May meeting.`,
    link: articleLink,
  },
  {
    image: home7,
    title: `Cybercrime is digital banking's greatest threat, bankers say`,
    description: `Banks and credit unions continue to identify cybersecurity threats and fraud as the greatest challenges to their digital banking strategy, according to new research by American Banker.`,
    link: article5Link,
  },
  {
    image: home6,
    title: `New 'Dirty Deeds' law allows clerks statewide to demand photo ID for
        property transfers`,
    description: `Since 2019, WFAA’s “Dirty Deeds” investigation has exposed how thieves have stolen properties they don't own by filing fraudulent deeds with county clerks.`,
    link: article6Link,
  },
  {
    image: home1,
    title: `Stealing home: Dozens of Arizona houses fraudulently sold without
        owners’ knowledge`,
    description: `The state Attorney General’s Office said it has received dozens of complaints about properties being sold without the owners’ knowledge by unknown scammers who filed forged warranty deeds with the county recorder’s office.`,
    link: article1Link,
  },
  {
    image: home2,
    title: `Three LA Homeowners warn against deed theft after their properties were
        stolen`,
    description: `Three homeowners in Los Angeles County are warning against deed fraud after they claim a man filed fraudulent deeds on their properties, claiming they all belonged to him.`,
    link: article2Link,
  },
  {
    image: home3,
    title: `Deed fraud defendant flees hearing after forged signatures detailed in
        testimony`,
    description: `Realtor snapped a picture at the title company of a couple selling a house in 2019. But prosecutors say the house wasn’t theirs to sell.`,
    link: article3Link,
  },

  // Add more stories
];

const RealStoryDesign = ({
  subTitle = "Educational",
  title = "Real stories about title fraud",
  IsGrid = false,
  readMoreClass = null,
  isBlogsSection = false,
}) => {
  const [isSwiping, setIsSwiping] = useState(false);

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.13,
    slidesToScroll: 2,
    centerMode: true,
    initialSlide: 0,
    // centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.0,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.03,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
    beforeChange: () => {
      setIsSwiping(true);
    },
    afterChange: () => {
      setIsSwiping(false);
    },
    onSwipe: () => {
      setIsSwiping(false);
    },
    swipeEvent: () => {
      setIsSwiping(true);
    },
  };

  // handle click on article
  const handleArticleClick = (url) => {
    if (isSwiping) {
      return;
    }
    window.open(url, "_blank");
  };

  const renderStoryCard = (story, index) => (
    <div className="realStoriesCard">
      <div
        className="real_stories h-100"
        onClick={() => {
          setIsSwiping(false);
          handleArticleClick(story?.link);
        }}
      >
        <LazyLoadImage
          src={story?.image}
          className="w-100"
          style={{
            width: "100%",
            height: "227px",
          }}
          alt="home"
        />
        <div className="articals content-spacing">
          <p className="notify_header mb-3">Real story</p>
          <p className="privateGuardList p-0">{story?.title}</p>
          <ReadMoreText readMoreClass={readMoreClass}>
            {story?.description}
          </ReadMoreText>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <div className=" text-center">
            <div className="mb-5">
              {subTitle !== "hide" && (
                <p className="notify_header">{subTitle}</p>
              )}
              <h2 className="sub_header">{title}</h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="realStoryWrapper">
        <div className="realStoryWrapper">
          {IsGrid ? (
            <div className="grid-layout">
              <Row>
                {stories?.map((story, index) => (
                  <Col key={index} sm={12} md={6} lg={4} className="mb-4">
                    {renderStoryCard(story, index)}
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Slider {...settings}>
              {stories?.map((story, index) => renderStoryCard(story, index))}
            </Slider>
          )}
        </div>
      </div>

      {isBlogsSection && (
        <>
          <Row className="my-3">
            <Col>
              <div className=" text-center">
                <div className="my-5">
                  <h2 className="sub_header">Blogs</h2>
                </div>
              </div>
            </Col>
          </Row>
          <div className="realStoryWrapper">
            <div className="realStoryWrapper">
              {IsGrid ? (
                <div className="grid-layout">
                  <Row>
                    {blog_stories.map((story, index) => (
                      <Col key={index} sm={12} md={6} lg={4} className="mb-4">
                        {renderStoryCard(story, index)}
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <Slider {...settings}>
                  {blog_stories?.map((story, index) =>
                    renderStoryCard(story, index)
                  )}
                </Slider>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default React.memo(RealStoryDesign);
