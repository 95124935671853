import { useRef } from "react";
import { useCallback } from "react";
import React, { Suspense } from "react";
import { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import PageNotFound from "./PageNotFound";
import RouteProtection from "./RouteProtection";
import useInjectSchema from "./useInjectSchema";
import NavBar from "../components/layout/navbar";
import Footer from "../components/layout/footer";
import { Loader } from "../components/common/loader";
import RoleProtectedRoute from "./RoleProtectedRoute";
import { setMetaDataForSEO } from "./setMetaDataForSEO";
import MiniSidebar from "../components/userProfile/index";
import AlertDialog from "../components/common/AlertDialog";
import AdminSideBar from "../components/admin/AdminSideBar";
import LeadPage, { Transition } from "../components/lead-page";
import UserAdminNavBar from "../components/layout/userAdminNavbar";
import TryDomainNavBar from "../components/layout/TryDomainNavBar";
import ZendeskSVG from "../components/common/customSVGs/ZendeskSVG";
import ServicerSideBar from "../components/Service-Provider/sideBar";
import { userSigupAction } from "../redux/slices/user/userSignUpSlice";
import { propertiesAction } from "../redux/slices/properties/propertiesSlice";
import ProfessionalSideBar from "../components/professional/ProfessionalSideBar";
import { exportCSVDetialsAction } from "../redux/slices/export-csv/exportcsvSlice";
import ServiceProvidersAdminNavbar from "../components/layout/serviceProvidersAdminNavbar";
import {
  encryptPayload,
  getObjLength,
  loadScriptByURL,
} from "../components/helpers/utils";
import { adminDashboardAction } from "../redux/slices/admin/admin-dashboard/adminDashboardSlice";
import { propertyRiskManagementAction } from "../redux/slices/admin/property-risk-management/propertyRiskManagementSlice";
import {
  DEFAULT_URL,
  RECAPTCHA_SITE_KEY,
  ROLES,
  SERVER,
  SUBDOMAIN,
  ZENDESK_SECRET_KEY,
} from "../components/helpers/constants";
import {
  adminRoutes,
  forServiceProvicerRoute,
  lannerPublicRoutes,
  lannerRestrictedRoutes,
  loggedInUserRoutes,
  professionalRoutes,
  publicRoutes,
  serviceProviderLoginRoutes,
  serviceProviderPublicRoutes,
  serviceProviderRoutes,
  tryDomainRoutes,
  userAdminRoutes,
} from "./routerLists";
const RootRouter = () => {
  const location = useLocation();
  const navType = useNavigationType();
  const dispatch = useDispatch();
  const modalCaptchaRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // states
  const [openAlert, setOpenAlert] = useState(true);
  const [flag, setFlag] = useState(true);
  const [isZendeskCaptchaModalOpen, setIsZendeskCaptchaModalOpen] =
    useState(false);
  const [zendeskScriptIsLoading, setZendeskScriptIsLoading] = useState(false);
  const [siteURL, setSiteURL] = useState(window?.location?.href);
  const [schemaData, setSchemaData] = useState(null);

  // store data
  const { checkReCAPTCHADetails, checkReCAPTCHALoader } = useSelector(
    (state) => state?.signUpUser
  );
  const { countyData } = useSelector((state) => state?.propertyRiskManagement);
  const { profileLoader } = useSelector((state) => state?.userProfile);

  // constants
  const isCaptchaVerified = sessionStorage.getItem("ZD-captcha-verified");
  const siteHost = window.location.host;
  const pathname = window.location.pathname;
  const addUserPath = pathname?.split("/")?.[1];
  const countyCode = pathname?.split("/")?.[2];
  localStorage.setItem("path", encryptPayload(pathname));
  const params = window.location.search;
  let paramString = params.split("?")[1];
  let valuOf = paramString?.split("=")[0];
  let code = params?.split("=")[1];

  if (valuOf === "plan_renewal") {
    console.log(valuOf, "==plan_renewal==>", code);
    localStorage.setItem("plan_renewal", code);
  }

  if (code === "ios" || code === "android") {
    localStorage.setItem("isMobile", encryptPayload(code));
  } else {
    // localStorage.removeItem("isMobile");
  }

  const isBot = /bot|crawl|slurp|spider/i.test(navigator.userAgent);

  const user = localStorage.getItem("authorised");
  const userPreferences = localStorage.getItem("preference");

  useEffect(() => {
    if (
      countyCode &&
      countyCode !== "create-account" &&
      countyCode !== "pricing" &&
      addUserPath !== "add-user-details" &&
      !pathname.includes("admin") &&
      !pathname.includes("report-results-investor") &&
      !pathname.includes("investor-login") &&
      !pathname.includes("investor-list")
    ) {
      dispatch(
        propertyRiskManagementAction.countyData({
          url: `county/details`,
          data: { county: countyCode },
        })
      );
    }
    if (
      !countyCode &&
      !(
        location.pathname.includes("prager") ||
        location.pathname.includes("gallagher") ||
        location.pathname.includes("gorka")
      )
    ) {
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: "get-plans",
          data: {},
          type: "post",
        })
      );
    }
  }, [countyCode, dispatch]);

  useEffect(() => {
    if (getObjLength(countyData)) {
      if (countyData?.success) {
        dispatch(
          userSigupAction.getSubscriptionPlan({
            url: `county/${countyData?.data?.id}/get-plans`,
            data: {},
            type: "post",
          })
        );
      }
    }
  }, [countyData, dispatch]);

  const userPreference = (access) => {
    localStorage.setItem("preference", access);
    setOpenAlert(false);
  };
  console.log(valuOf, "--code-->", code);

  function cookieConsent() {
    return (
      <>
        {(localStorage.getItem("preference") === null ||
          localStorage.getItem("preference").length === 0) &&
          code !== "ios" && (
            <AlertDialog
              type="Warning"
              // width="md"
              openPopup={openAlert}
              onClose={false}
              mesage={
                <Box className="text-start">
                  <Typography className=" fw-600 text-center m-3" variant="h6">
                    This website uses cookies
                  </Typography>
                  <p className="m-4 fw-500">
                    We use essential cookies to make our site work. With your
                    consent, we may also use non-essential cookies to improve
                    user experience, share information about your use of our
                    site with our social media partners, advertising, analytics
                    partners and analyze website traffic. By clicking “ACCEPT”,
                    you agree to our website's cookie use. You can change your
                    cookie settings at any time by clicking “Preferences.”
                  </p>
                </Box>
              }
              buttons={
                <>
                  <Button
                    className="popup_Btn other_popup_btn m-2"
                    color="error"
                    onClick={() => userPreference(false)}
                  >
                    Deny
                  </Button>
                  <Button
                    className="popup_Btn success_popup_btn"
                    onClick={() => userPreference(true)}
                  >
                    Accept
                  </Button>
                </>
              }
            />
          )}
      </>
    );
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log('Cookie called');
  //     setFlag(true);
  //     // cookieConsent();
  //   }, 5000);

  //   // Cleanup the timer if the component unmounts before the timeout is finished
  //   return () => clearTimeout(timer);
  // }, []);

  function GTagManager() {
    return (
      <Helmet>
        {/* Google tag manager to load every page. */}
        {/* {SERVER === "staging" && ( */}
        <script>
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-KB5KV24")}
        </script>
        {/* )} */}
      </Helmet>
    );
  }

  const handleZendeskCaptchaModalOpen = () => {
    setIsZendeskCaptchaModalOpen(true);
  };

  const handleCloseZendeskModal = useCallback(() => {
    setIsZendeskCaptchaModalOpen(false);
    modalCaptchaRef?.current?.reset();
    dispatch(userSigupAction.setreCAPTCHA({}));
  }, [dispatch]);

  const handleCaptchaChangeModal = (captchaValue) => {
    if (captchaValue) {
      dispatch(
        userSigupAction.checkreCAPTCHA({
          url: `validate-captcha?token=${captchaValue}`,
          data: {},
        })
      );
    }
  };

  const handleLoadZendeskScript = () => {
    setZendeskScriptIsLoading(true);
    loadScriptByURL(
      "ze-snippet",
      `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_SECRET_KEY}`,
      () => {
        // console.log(
        //   "zendesk script is loaded",
        //   window.zE("webWidget", "status")
        // );
        window.zE("messenger", "open");

        //  window.zE("messenger:on", "open", function () {
        //   setZendeskScriptIsLoading(false);
        // });
        // console.log("window.zE", window.zE.status);

        // if (window.zE.isWidgetOpen()) {
        //   setZendeskScriptIsLoading(false);
        // }

        setTimeout(() => setZendeskScriptIsLoading(false), 3000);
      }
    );
  };

  // logic for scrolling smoothly to the top of the page
  useEffect(() => {
    if (navType !== "POP") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location, navType]);
  // useEffect(() => {
  // if (SERVER === "production") {
  // GTagManager();
  // }
  // }, [location]);

  // open zendesk widgets if captcha verify
  useEffect(() => {
    if (
      getObjLength(checkReCAPTCHADetails) &&
      checkReCAPTCHADetails?.data?.success &&
      isZendeskCaptchaModalOpen &&
      !isCaptchaVerified
    ) {
      sessionStorage.setItem("ZD-captcha-verified", true);
      handleLoadZendeskScript();
      handleCloseZendeskModal();
    }

    if (isCaptchaVerified) {
      loadScriptByURL(
        "ze-snippet",
        `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_SECRET_KEY}`,
        () => {}
      );
    }
  }, [
    checkReCAPTCHADetails,
    handleCloseZendeskModal,
    isCaptchaVerified,
    isZendeskCaptchaModalOpen,
  ]);

  useEffect(() => {
    // Function to periodically check for the iframe and adjust its style
    console.log("isCaptchaVerified==>", isCaptchaVerified);
    if (isMobile) {
      const checkAndAdjustIframe = () => {
        const iframe = document.getElementById("launcher");
        const iframeApexBot = document.getElementById(
          "apexchat_prechat_invitation_frame"
        );
        const divApexBot = document.getElementById(
          "apexchat_prechat_invitation_wrapper"
        );
        let iframeMessage = document.querySelector(
          'iframe[title="Message from company"]'
        );
        let iframeCloseMessage = document.querySelector(
          'iframe[title="Close message"]'
        );
        let iframeNumberMessage = document.querySelector(
          'iframe[title="Number of unread messages"]'
        );
        console.log("isMobile-->", iframeMessage);

        if (iframe) {
          // Modify the style attribute of the iframe
          iframe.style.bottom = "60px";
          iframe.style.right = "unset";
          iframe.style.left = "12px";

          if (iframeMessage) {
            iframeMessage.style.position = "fixed";
            iframeMessage.style.bottom = "135px";
            iframeMessage.style.right = "unset";
            iframeMessage.style.left = "60px";
          }
          if (iframeCloseMessage) {
            iframeCloseMessage.style.position = "fixed";
            iframeCloseMessage.style.bottom = "135px";
            iframeCloseMessage.style.right = "unset";
            iframeCloseMessage.style.left = "13px";
          }
          if (iframeNumberMessage) {
            iframeNumberMessage.style.position = "fixed";
            iframeNumberMessage.style.bottom = "115px";
            iframeNumberMessage.style.right = "unset";
            iframeNumberMessage.style.left = "0px";
          }

          // if (iframeApexBot) {
          //   iframeApexBot.style.display = "none";
          //   iframeApexBot.style.right = "unset";
          //   iframeApexBot.style.height = "0px";
          // }
          // if (divApexBot) {
          //   divApexBot.style.display = "none";
          //   divApexBot.style.right = "unset";
          //   divApexBot.style.height = "0px";
          // }
          if (
            iframeMessage &&
            iframe &&
            iframeCloseMessage &&
            iframeNumberMessage
          ) {
            clearInterval(intervalId); // Clear the interval once the iframe is found and style is adjusted
          }
        } else {
          setTimeout(() => {
            clearInterval(intervalId);
          }, 20000);
        }
      };
      // Check every second for the iframe
      const intervalId = setInterval(checkAndAdjustIframe, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [isCaptchaVerified]);

  useEffect(() => {
    // Check if it's a staging URL or staging server
    if (
      window.location.hostname.includes("staging") ||
      SERVER !== "production"
    ) {
      // Create a new meta element
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex";

      // Append the meta element to the head
      document.head.appendChild(metaTag);
    }

    return () => {
      const existingMetaTag = document.querySelector('meta[name="robots"]');
      if (existingMetaTag) {
        document.head.removeChild(existingMetaTag);
      }
    };
  }, []);

  // Check change of pathname
  useEffect(() => {
    console.log("pathname==>", pathname);
    if (pathname === "/" || pathname === "/equity-protect") {
      const navbar = document.getElementById("HeaderNavbar");

      localStorage.removeItem("scrp_hp");
      localStorage.removeItem("county-code");
      //if (navbar && navbar?.classList?.contains("sticky")) {
      navbar?.classList?.remove("sticky");
      if (navbar?.classList?.contains("bg-dark")) {
        navbar.classList.remove("bg-dark");
        navbar.classList.add("bg-transparent");
      }
      dispatch(propertyRiskManagementAction.setNavbarColor(false));
      //  }
    }

    // for clear the proeprty risk form redux values
    if (!pathname.includes("property-risk")) {
      dispatch(
        propertyRiskManagementAction.clearPropertyRiskManagementData({})
      );
    }

    if (
      !pathname.includes("create-account") &&
      !pathname.includes("property-risk") &&
      !pathname.includes("property-risk-report")
    ) {
      localStorage.removeItem("property_search_data");
      localStorage.removeItem("investor_id");
      localStorage.removeItem("user_details");
      localStorage.removeItem("isRiskAssessment");
    }
    if (
      !pathname.includes("professional-properties") &&
      !pathname.includes("servicer-properties") &&
      !pathname.includes("properties")
    ) {
      dispatch(propertiesAction.resetState());
    }

    if (code === "req_doc_email") {
      localStorage.setItem("code", code);
    }

    if (!pathname.includes("admin-properties")) {
      localStorage.removeItem("tempPropertyFilterData");
      dispatch(adminDashboardAction.getSubscriptionModuleFilterData({}));
    }
    if (!pathname.includes("admin-users")) {
      localStorage.removeItem("tempUsersFilterData");
      dispatch(adminDashboardAction.getUsersModuleFilterData({}));
    }
    if (!pathname.includes("admin-property-monitoring")) {
      localStorage.removeItem("tempMonitoringFilterData");
      dispatch(adminDashboardAction.getMonitoringPropertiesFilterData({}));
    }

    dispatch(exportCSVDetialsAction.getExportCSVFile({})); // cleared the common export csv redux values
  }, [pathname]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      // Disable default scroll restoration on back/forward navigation
      window.history.scrollRestoration = "manual";
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, [pathname]); // Trigger this effect whenever the pathname changes

    return null;
  };

  useEffect(() => {
    // Set the schema and title & metadata based on the current route
    const schemaData = setMetaDataForSEO(pathname, window?.location?.hostname);
    setSchemaData(schemaData); // Store schema data in state

    // Clean up function to remove the meta tag if needed
    return () => {
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        document.head.removeChild(metaDescription);
      }
    };
  }, [location]);

  // Call useInjectSchema at the top level (following React rules)
  useInjectSchema(pathname, schemaData); //Injects and removes script dynamically

  useEffect(() => {
    console.log("siteURL==>", siteHost);
    let isProvider = null;
    if (siteHost.split(".")[0]?.includes(SUBDOMAIN?.servicer)) {
      isProvider = siteHost;
      if (pathname === "/" || pathname === "/property-risk-management") {
        setSiteURL(DEFAULT_URL + pathname?.replace("/", ""));
      } else {
        setSiteURL(window?.location?.href);
      }
    } else {
      setSiteURL(window?.location?.href);
    }
    console.log(siteURL, "servicer==>", isProvider);
  }, [siteHost, pathname]);

  return (
    <>
      <Helmet>
        {/* window.location.href will get URL with sub domain also so no duplicated URL will encounter in this */}
        <link rel="canonical" href={siteURL} />
      </Helmet>

      <Suspense fallback={<Loader open={true} />}>
        {
          ScrollToTop() // This will scroll to top on every route change */}
        }
        <Routes>
          {
            (window.location.hostname
              .split(".")[0]
              ?.includes(SUBDOMAIN?.equityprotect) ||
              window.location.hostname
                .split(".")[0]
                ?.includes(SUBDOMAIN?.provider)) &&
            window.location.hostname.split(".")[0] !== "staging" ? (
              <>
                {lannerRestrictedRoutes.map(
                  ({ component, path, title, role }, key) => (
                    <Route
                      path={path}
                      element={
                        <>
                          <RoleProtectedRoute accessEntity={role}>
                            {component}
                          </RoleProtectedRoute>
                        </>
                      }
                      key={key}
                    />
                  )
                )}

                {/* public route */}
                {lannerPublicRoutes?.map(({ component, path }, key) => (
                  <Route path={path} element={<>{component}</>} key={key} />
                ))}
              </>
            ) : window.location.hostname.split(".")[0] === SUBDOMAIN.try &&
              window.location.hostname.split(".")[0] !== "staging" ? (
              <>
                {/* try domain public routes */}
                {tryDomainRoutes.map(({ component, path, title }, key) => (
                  <Route
                    path={path}
                    element={
                      <>
                        <TryDomainNavBar />
                        {component}
                        {/* <Footer isTryPage={true} /> */}
                      </>
                    }
                    key={key}
                  />
                ))}
              </>
            ) : window.location.hostname
                .split(".")[0]
                ?.includes(SUBDOMAIN.servicer) &&
              window.location.hostname.split(".")[0] !== "staging" ? (
              <>
                {serviceProviderRoutes.map(
                  ({ component, path, title }, key) => (
                    <Route
                      path={path}
                      element={
                        <>
                          <ServiceProvidersAdminNavbar />
                          {component}
                        </>
                      }
                      key={key}
                    />
                  )
                )}

                {/* public route */}
                {serviceProviderPublicRoutes?.map(
                  (
                    {
                      component,
                      path,
                      title,
                      role,
                      isLandingPage = false,
                      hideNavbar = false,
                    },
                    key
                  ) => (
                    <Route
                      path={path}
                      element={
                        <>
                          {!hideNavbar && (
                            <NavBar
                              isLandingPage={isLandingPage}
                              roles={[ROLES?.serviceProvider]}
                            />
                          )}
                          {component}
                          <LazyLoadComponent threshold={150}>
                            <Footer isTryPage={false} />
                          </LazyLoadComponent>
                        </>
                      }
                      key={key}
                    />
                  )
                )}

                {forServiceProvicerRoute?.map(
                  ({ component, path, title, role }, key) => (
                    <Route
                      path={path}
                      element={
                        <>
                          <NavBar roles={[ROLES?.serviceProvider]} />
                          {component}
                          <LazyLoadComponent threshold={150}>
                            <Footer isTryPage={false} />
                          </LazyLoadComponent>
                        </>
                      }
                      key={key}
                    />
                  )
                )}

                {/* loggedIn Servicer Routes */}
                {serviceProviderLoginRoutes.map(
                  ({ component, path, title, role }, key) =>
                    role.includes(ROLES.serviceProvider) ? (
                      <Route
                        path={path}
                        element={
                          <>
                            <RouteProtection>
                              <RoleProtectedRoute accessEntity={role}>
                                <ServicerSideBar comp={component} key={key} />
                              </RoleProtectedRoute>
                            </RouteProtection>
                          </>
                        }
                        key={key}
                      />
                    ) : (
                      <Route path="/" element={<PageNotFound />} /> //404
                    )
                )}
              </>
            ) : (
              // window.location.hostname.split(".").length === 1 ? (
              // || window.location.hostname.split(".")[0] === SERVER
              <>
                {/*------- public routes -------*/}
                {publicRoutes.map(
                  (
                    {
                      component,
                      path,
                      title,
                      hideNavbar,
                      isLandingPage = false,
                      stickyNavbar = false,
                      hideServiceProviderFromNavbar = false,
                      footer = true,
                      isAttorney = false,
                      isInvestors = false,
                      isSiteMap = true,
                    },
                    key
                  ) => (
                    <Route
                      path={path}
                      element={
                        // TEMPORARY route
                        title === "Lennar" ? (
                          component
                        ) : (
                          <>
                            {!hideNavbar && (
                              <NavBar
                                isLandingPage={isLandingPage}
                                stickyNavbar={stickyNavbar}
                                hideServiceProviderFromNavbar={
                                  hideServiceProviderFromNavbar
                                }
                                isAttorney={isAttorney}
                              />
                            )}
                            {component}
                            {footer && (
                              <LazyLoadComponent threshold={150}>
                                <Footer
                                  isTryPage={false}
                                  isAttorney={isAttorney}
                                  isInvestors={isInvestors}
                                  isSiteMap={isSiteMap}
                                />
                              </LazyLoadComponent>
                            )}

                            {!isBot && flag && cookieConsent()}
                          </>
                        )
                      }
                      key={key}
                    />
                  )
                )}
                {SERVER !== "production" ? (
                  <Route
                    path="/lead"
                    element={
                      <>
                        <TryDomainNavBar />
                        <LeadPage />
                      </>
                    }
                    key={"lead"}
                  />
                ) : null}

                {/*------- user-admin routers -------*/}
                {userAdminRoutes.map(
                  ({ component, path, title, footer, hideLoginURL }, key) => (
                    <Route
                      path={path}
                      element={
                        <>
                          <UserAdminNavBar hideLoginURL={hideLoginURL} />
                          {component}
                          {cookieConsent()}
                          {footer && <Footer isTryPage={false} />}
                        </>
                      }
                      key={key}
                    />
                  )
                )}

                {/*------- logged in users router -------*/}
                {loggedInUserRoutes.map(
                  ({ component, path, title, role }, key) =>
                    role.includes(ROLES.customer) ||
                    role.includes(ROLES.secondaryUser) ? (
                      <Route
                        path={path}
                        element={
                          <>
                            <RouteProtection>
                              <RoleProtectedRoute accessEntity={role}>
                                <MiniSidebar comp={component} key={key} />
                              </RoleProtectedRoute>
                            </RouteProtection>
                            {cookieConsent()}
                          </>
                        }
                        key={key}
                      />
                    ) : (
                      <Route path="/" element={<PageNotFound />} /> //404
                    )
                )}
                {/* Admin Routes */}
                {adminRoutes.map(({ component, path, title, role }, key) =>
                  role.includes(ROLES.admin) ||
                  role.includes(ROLES.subAdmin) ||
                  role.includes(ROLES.provider) ||
                  role.includes(ROLES.countyRecorder) ? (
                    <Route
                      path={path}
                      element={
                        <>
                          <RouteProtection>
                            <RoleProtectedRoute accessEntity={role}>
                              <AdminSideBar comp={component} key={key} />
                            </RoleProtectedRoute>
                          </RouteProtection>
                        </>
                      }
                      key={key}
                    />
                  ) : (
                    <Route path="/" element={<PageNotFound />} /> //404
                  )
                )}

                {/* Professional Routes */}
                {professionalRoutes.map(
                  ({ component, path, title, role }, key) =>
                    role.includes(ROLES.professional) ? (
                      <Route
                        path={path}
                        element={
                          <>
                            <RouteProtection isIOS={code}>
                              <RoleProtectedRoute
                                accessEntity={role}
                                isIOS={code}
                              >
                                <ProfessionalSideBar
                                  comp={component}
                                  key={key}
                                />
                              </RoleProtectedRoute>
                            </RouteProtection>
                          </>
                        }
                        key={key}
                      />
                    ) : (
                      <Route path="/" element={<PageNotFound />} /> //404
                    )
                )}
              </>
            )

            // ) : (
            //   <>
            //     <Route path="/*" element={<Navigate to="/" />} /> //404
            //     <Route path="/" element={<PageNotFound />} /> //404
            //   </>
            // )
          }
          <Route path="/*" element={<Navigate to="/" />} /> {/* 404 */}
          <Route
            path={siteHost?.includes(SUBDOMAIN?.provider) ? "/404" : "/"}
            element={<PageNotFound />}
          />{" "}
          {/* 404 */}
        </Routes>
      </Suspense>

      {/* zendesk button */}
      {!isCaptchaVerified && (
        <IconButton
          sx={{ zIndex: "999999" }}
          className={`zendesk-btn ${isMobile ? "zendesk_bottom" : ""}`}
          onClick={() => handleZendeskCaptchaModalOpen()}
        >
          <ZendeskSVG />
        </IconButton>
      )}

      {/* captcha modal for calendly */}
      {isZendeskCaptchaModalOpen && (
        <Dialog
          open={isZendeskCaptchaModalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseZendeskModal}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Verify Yourself"}</DialogTitle>
          <DialogContent>
            <Stack className="pt-0 pt-md-4">
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChangeModal}
                className="g-recaptcha"
                ref={modalCaptchaRef}
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      <Loader
        open={checkReCAPTCHALoader || zendeskScriptIsLoading || profileLoader}
      />
    </>
  );
};
export default RootRouter;
